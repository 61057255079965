<template>
  <b-card
    v-if="data"
    title="Overzicht totalen"
    class="card-statistics"
  >
    <b-row class="match-height statistics-body" style="padding-top: 0 !important;margin-top:0 !important;">
      <b-col
        v-for="(label, index) in data"
        :key="index"
      >
        <b-row class="mb-1">
          <b-col><span class="title"><strong>{{ label.name ? label.name : "" }}</strong></span></b-col>
        </b-row>
        <b-row>
          <b-col>Aantal</b-col>
          <b-col>{{ label.count ? label.count : "0" }}</b-col>
        </b-row>
        <b-row>
          <b-col>SRD</b-col>
          <b-col>{{ label.now ? kFormatter(label.now) : "0" }}</b-col>
        </b-row>
        <b-row>
          <b-col>T.o.v vorig jaar</b-col>
          <b-col>
            <div v-if="label.now - label.last >= 0 && label.name !== 'Royement'">
              <span
                class="mb-0"
                style="color:#378805"
              >{{ label.last ? ((label.now/label.last)*100).toFixed(2) : "0" }}%
              </span>
            </div>
            <div v-if="label.now - label.last < 0 && label.name !== 'Royement'">
              <hspan
                class="mb-0"
                style="color:#FF0000"
              >{{ label.last ? ((label.now/label.last)*100).toFixed(2) : "0" }}%
              </hspan>
            </div>
            <div v-if="label.now - label.last > 0 && label.name === 'Royement'">
              <span
                class="mb-0"
                style="color:#FF0000"
              >{{ label.last ? ((label.now/label.last)*100).toFixed(2) : "0" }}%
              </span>
            </div>
            <div v-if="label.now - label.last <= 0 && label.name === 'Royement'">
              <span
                class="mb-0"
                style="color:#378805"
              >{{ label.last ? ((label.now/label.last)*100).toFixed(2) : "0" }}%
              </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
