<template>
  <b-card
    v-if="tableData"
    title="Specificatie kosten huidige maand"
  >
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-table
          v-if="tableData"
          :items="tableData"
          responsive
          :fields="fields"
        >
          <!-- company -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.name }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(agentProvision)="data">
            <strong><small>SRD</small> {{ data.item.agentProvision ? kFormatter(data.item.agentProvision) : "0" }}</strong><br>
          </template>
          <template #cell(totalCost)="data">
            <strong><small>SRD</small> {{ data.item.totalCost ? kFormatter(data.item.totalCost) : "0" }}</strong>
          </template>
          <template #cell(premiumExclRoyement)="data">
            <strong><small>SRD</small> {{ data.item.premiumExclRoyement ? kFormatter(data.item.premiumExclRoyement) : "0" }}</strong>
          </template>
          <template #cell(maxLoss)="data">
            <strong>{{ data.item.maxLoss ? data.item.maxLoss : "0" }}%</strong>
          </template>
          <template #cell(maxCombined)="data">
            <strong>{{ data.item.maxCombined ? data.item.maxCombined : "0" }}%</strong>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCol,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BCol,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: '' },
        { key: 'agentProvision', label: 'Kosten' },
        { key: 'totalCost', label: 'Totale kosten' },
        { key: 'premiumExclRoyement', label: 'Premie excl. royement' },
        { key: 'maxLoss', label: 'Max loss' },
        { key: 'maxCombined', label: 'Max combined' },
      ],
    }
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
