<template>
  <b-overlay
    rounded="sm"
    :show="!data"
  >
    <b-card
      style="min-height:260px"
    >
      <b-card-title class="mb-1">
        {{ title }}
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Huidige maand
      </b-card-sub-title>

      <vue-apex-charts
        v-if="data"
        type="donut"
        height="350"
        :options="data.chartOptions"
        :series="data.series"
      />
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-row colspan="2">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h1 class="font-small-3 font-weight-bolder mb-0">
                Productie
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <h4 class="mb-0">
                <small>SRD</small> {{ total ? kFormatter(total) : "0" }}
              </h4>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-row colspan="2">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h1 class="font-small-3 font-weight-bolder mb-0">
                Royement
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div>
                <h4
                  class="mb-0"
                >
                  <small>SRD</small> {{ royement ? kFormatter(royement) : "0" }}
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="text-center mx-0">
        <b-col
          cols="12"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-col
            cols="12"
            md="12"
          ><h4 class="mb-0">
            <strong>Totaal </strong><strong>SRD {{ finalTotal ? kFormatter(finalTotal) : "0" }}</strong>
          </h4>
          </b-col>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <!-- Company Table Card -->
        <b-col cols="12">
          <specification-table
            :table-data="tableData"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import SpecificationTable from '@/views/pages/dashboard/dashboard-component/SpecificationTable.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BRow,
    BCol,
    SpecificationTable,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: () => null,
    },
    royement: {
      type: Number,
      default: () => null,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      total: null,
      finalTotal: null,
    }
  },
  created() {
    this.calculateTotals()
  },
  methods: {
    calculateTotals() {
      let total = 0
      this.data.series.forEach(i => {
        total += i
      })
      this.total = total
      this.finalTotal = this.total - this.royement
    },
    kFormatter,
  },
}
</script>
<style>
.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center {
  justify-content: left !important;
}
</style>
