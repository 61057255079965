<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        v-if="tableData && tableData.total"
        cols="12"
      >
        <specification-statistics
          v-if="tableData && tableData.total"
          :data="tableData.total"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col
        v-if="tableDataCost && tableDataCost.total"
        cols="12"
      >
        <specification-cost-table
          :table-data="tableDataCost.total"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="donutData"
        cols="12"
        md="6"
      >
        <donut-chart-statistics
          :data="fixDonut('TOR')"
          title="TOR productie"
          :royement="donutData.TOR.royement"
          :table-data="tableData.TOR"
        />
      </b-col>
      <b-col
        v-if="donutData"
        cols="12"
        md="6"
      >
        <donut-chart-statistics
          :data="fixDonut('BFS')"
          title="BFS productie"
          :royement="donutData.BFS.royement"
          :table-data="tableData.BFS"
        />
      </b-col>
      <b-col
        v-if="donutData"
        cols="12"
        md="6"
      >
        <donut-chart-statistics
          :data="fixDonut('APSR')"
          title="APSR productie"
          :royement="donutData.APSR.royement"
          :table-data="tableData.APSR"
        />
      </b-col>
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        md="6"-->
      <!--      >-->
      <!--        <donut-chart-statistics-->
      <!--          :data="fixDonut('FSP')"-->
      <!--          title="FSP productie"-->
      <!--          :royement="donutData.FSP.royement"-->
      <!--          :table-data="tableData.FSP"-->
      <!--        />-->
      <!--      </b-col>-->
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        md="6"-->
      <!--      >-->
      <!--        <donut-chart-statistics-->
      <!--          :data="fixDonut('SKV')"-->
      <!--          title="SKV productie"-->
      <!--          :royement="donutData.SKV.royement"-->
      <!--          :table-data="tableData.SKV"-->
      <!--        />-->
      <!--      </b-col>-->
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        md="6"-->
      <!--      >-->
      <!--        <donut-chart-statistics-->
      <!--          :data="fixDonut('TIR')"-->
      <!--          title="TIR productie"-->
      <!--          :royement="donutData.TIR.royement"-->
      <!--          :table-data="tableData.TIR"-->
      <!--        />-->
      <!--      </b-col>-->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import dashboardStoreModule from '@/views/pages/dashboard/dashboardStoreModule'
import SpecificationStatistics from '@/views/pages/dashboard/dashboard-component/SpecificationStatistics.vue'
import DonutChartStatistics from '@/views/pages/dashboard/dashboard-component/DonutChartStatistics.vue'
import SpecificationCostTable from '@/views/pages/dashboard/dashboard-component/SpecificationCostTable.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    DonutChartStatistics,
    SpecificationStatistics,
    SpecificationCostTable,
  },
  data() {
    return {
      donutData: null,
      // donutData: {
      //   total: { productie: [1207336.92, 868871.6, 0], royement: 50988.54 },
      //   TOR: { productie: [52891.39, 702494.08, 0], royement: 0 },
      //   BFS: { productie: [67629.4, 12630.3, 0], royement: 0 },
      //   APSR: { productie: [67629.4, 12630.3, 0], royement: 44894.38 },
      //   FSP: { productie: [1207336.92, 868871.6, 0], royement: 2095.16 },
      //   SKV: { productie: [1207336.92, 868871.6, 0], royement: 3999 },
      //   TIR: { productie: [1207336.92, 868871.6, 0], royement: 0 },
      // },
      tableData: null,
      // tableData: {
      //   total: [
      //     {
      //       name: 'Nieuw', count: 73, now: 52891.39, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 194, now: 702494.08, last: 175,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 267, now: 755385.47, last: 180,
      //     },
      //   ],
      //   TOR: [
      //     {
      //       name: 'Nieuw', count: 73, now: 52891.39, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 194, now: 702494.08, last: 175,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 267, now: 755385.47, last: 180,
      //     },
      //   ],
      //   BFS: [
      //     {
      //       name: 'Nieuw', count: 2, now: 67629.40, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 15, now: 12630.3, last: 80,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 17, now: 80259.70, last: 550,
      //     },
      //   ],
      //   APSR: [
      //     {
      //       name: 'Nieuw', count: 4, now: 47535.57, last: 1500,
      //     },
      //     {
      //       name: 'Prolongaties', count: 0, now: 103759.72, last: 150,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 7, now: 44894.38, last: 620,
      //     },
      //     {
      //       name: 'Totaal', count: -3, now: 105925.11, last: 160,
      //     },
      //   ],
      //   FSP: [
      //     {
      //       name: 'Nieuw', count: 0, now: 52891.39, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 1, now: 702494.08, last: 175,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 1, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 0, now: 755385.47, last: 180,
      //     },
      //   ],
      //   SKV: [
      //     {
      //       name: 'Nieuw', count: 0, now: 52891.39, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 0, now: 702494.08, last: 175,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 0, now: 755385.47, last: 180,
      //     },
      //   ],
      //   TIR: [
      //     {
      //       name: 'Nieuw', count: 1, now: 52891.39, last: 300,
      //     },
      //     {
      //       name: 'Prolongaties', count: 0, now: 702494.08, last: 175,
      //     },
      //     {
      //       name: 'Suppleties', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Royement', count: 0, now: 0, last: 100,
      //     },
      //     {
      //       name: 'Totaal', count: 1, now: 755385.47, last: 180,
      //     },
      //   ],
      // },
      donutLabels: ['Nieuw', 'Prolongaties', 'Suppleties'],
      tableDataCost: null,
      // tableDataCost: {
      //   total: [
      //     {
      //       name: 'TOR',
      //       agentProvision: 49333.53,
      //       claims: 23000,
      //       totalCost: 79593.53,
      //       premiumExclRoyement: 738386.54,
      //       maxLoss: 3.11,
      //       maxCombined: 10.78,
      //     },
      //     {
      //       name: 'BFS',
      //       agentProvision: 111.60,
      //       claims: 0,
      //       totalCost: 111.60,
      //       premiumExclRoyement: 80289.91,
      //       maxLoss: 0,
      //       maxCombined: 0.14,
      //     },
      //     {
      //       name: 'APSR',
      //       agentProvision: 0,
      //       claims: 0,
      //       totalCost: 44330.05,
      //       premiumExclRoyement: 105925.11,
      //       maxLoss: 10.44,
      //       maxCombined: 41.85,
      //     },
      //     {
      //       name: 'FSP',
      //       agentProvision: 978.9,
      //       claims: 0,
      //       totalCost: 44780.42,
      //       premiumExclRoyement: 92786.71,
      //       maxLoss: 0,
      //       maxCombined: 48.26,
      //     },
      //     {
      //       name: 'SKV',
      //       agentProvision: -79.98,
      //       claims: 0,
      //       totalCost: -79.98,
      //       premiumExclRoyement: -3999,
      //       maxLoss: 0,
      //       maxCombined: 2,
      //     },
      //     {
      //       name: 'TIR',
      //       agentProvision: 0,
      //       claims: 0,
      //       totalCost: 0,
      //       premiumExclRoyement: 1039280.57,
      //       maxLoss: 0,
      //       maxCombined: 0,
      //     },
      //   ],
      // },
    }
  },
  created() {
    this.fetchAllData()
  },
  methods: {
    fixDash2Data(data) {
      this.donutData = {
        total: { productie: [data.total.new.now, data.total.prolongation.now, data.total.suppletie.now], royement: data.total.royement.now },
        TOR: { productie: [data.TOR.new.now, data.TOR.prolongation.now, data.TOR.suppletie.now], royement: data.TOR.royement.now },
        BFS: { productie: [data.BFS.new.now, data.BFS.prolongation.now, data.BFS.suppletie.now], royement: data.BFS.royement.now },
        APSR: { productie: [data.APSR.new.now, data.APSR.prolongation.now, data.APSR.suppletie.now], royement: data.APSR.royement.now },
      }
      this.tableData = {
        total: [
          {
            name: 'Nieuw', count: data.total.new.count, now: data.total.new.now, last: data.total.new.last,
          },
          {
            name: 'Prolongaties', count: data.total.prolongation.count, now: data.total.prolongation.now, last: data.total.prolongation.last,
          },
          {
            name: 'Suppleties', count: data.total.suppletie.count, now: data.total.suppletie.now, last: data.total.suppletie.last,
          },
          {
            name: 'Royement', count: data.total.royement.count, now: data.total.royement.now, last: data.total.royement.last,
          },
          {
            name: 'Totaal', count: data.total.total.count, now: data.total.total.now, last: data.total.total.last,
          },
        ],
        TOR: [
          {
            name: 'Nieuw', count: data.TOR.new.count, now: data.TOR.new.now, last: data.TOR.new.last,
          },
          {
            name: 'Prolongaties', count: data.TOR.prolongation.count, now: data.TOR.prolongation.now, last: data.TOR.prolongation.last,
          },
          {
            name: 'Suppleties', count: data.TOR.suppletie.count, now: data.TOR.suppletie.now, last: data.TOR.suppletie.last,
          },
          {
            name: 'Royement', count: data.TOR.royement.count, now: data.TOR.royement.now, last: data.TOR.royement.last,
          },
          {
            name: 'Totaal', count: data.TOR.total.count, now: data.TOR.total.now, last: data.TOR.total.last,
          },
        ],
        BFS: [
          {
            name: 'Nieuw', count: data.BFS.new.count, now: data.BFS.new.now, last: data.BFS.new.last,
          },
          {
            name: 'Prolongaties', count: data.BFS.prolongation.count, now: data.BFS.prolongation.now, last: data.BFS.prolongation.last,
          },
          {
            name: 'Suppleties', count: data.BFS.suppletie.count, now: data.BFS.suppletie.now, last: data.BFS.suppletie.last,
          },
          {
            name: 'Royement', count: data.BFS.royement.count, now: data.BFS.royement.now, last: data.BFS.royement.last,
          },
          {
            name: 'Totaal', count: data.BFS.total.count, now: data.BFS.total.now, last: data.BFS.total.last,
          },
        ],
        APSR: [
          {
            name: 'Nieuw', count: data.APSR.new.count, now: data.APSR.new.now, last: data.APSR.new.last,
          },
          {
            name: 'Prolongaties', count: data.APSR.prolongation.count, now: data.APSR.prolongation.now, last: data.APSR.prolongation.last,
          },
          {
            name: 'Suppleties', count: data.APSR.suppletie.count, now: data.APSR.suppletie.now, last: data.APSR.suppletie.last,
          },
          {
            name: 'Royement', count: data.APSR.royement.count, now: data.APSR.royement.now, last: data.APSR.royement.last,
          },
          {
            name: 'Totaal', count: data.APSR.total.count, now: data.APSR.total.now, last: data.APSR.total.last,
          },
        ],
      }
    },
    fixDash3Data(data) {
      this.tableDataCost = {
        total: [
          {
            name: 'TOR',
            agentProvision: data.TOR.agentProvision,
            totalCost: data.TOR.totalCost,
            premiumExclRoyement: data.TOR.premiumExclRoyement,
            maxLoss: data.TOR.maxLoss,
            maxCombined: data.TOR.maxCombined,
          },
          {
            name: 'BFS',
            agentProvision: data.BFS.agentProvision,
            totalCost: data.BFS.totalCost,
            premiumExclRoyement: data.BFS.premiumExclRoyement,
            maxLoss: data.BFS.maxLoss,
            maxCombined: data.BFS.maxCombined,
          },
          {
            name: 'APSR',
            agentProvision: data.APSR.agentProvision,
            totalCost: data.APSR.totalCost,
            premiumExclRoyement: data.APSR.premiumExclRoyement,
            maxLoss: data.APSR.maxLoss,
            maxCombined: data.APSR.maxCombined,
          },
        ],
      }
    },
    fetchAllData() {
      Promise.all([
        store.dispatch('app-dashboard/fetchDashboardFinancialOverview'),
      ])
        .then(async ([resOverviewData]) => {
          this.fixDash2Data(resOverviewData.data.dash2)
          this.fixDash3Data(resOverviewData.data.dash3.total)
          // await this.fixLineData()
          // this.overviewInterval = setInterval(() => {
          //   this.fetchAllData()
          // }, 30000000000)
        })
        .catch(() => {
          // clearInterval(this.overviewInterval)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    fixDonut(asset) {
      let donutChart = {}
      donutChart = {
        series: this.donutData[asset].productie,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#fdb813',
            '#d31145',
            '#6e6b7b',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                },
              },
            },
          },
          labels: this.donutLabels,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
      return donutChart
    },
  },
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.echarts{
  width: 100% !important;
}
</style>
